import {
	ComponentRef,
	Directive,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface IFieldProtectionRuleContainerComponent {
	appId: number;
	projectId:number;
}

@Directive({
	standalone: true,
	selector: '[customAppFieldProtectionRuleLoaderDirective]',
	exportAs: 'CustomAppFieldProtectionRuleRemoteComponent',
})
export class CustomAppFieldProtectionRuleLoaderDirective
	implements OnInit, OnChanges, OnDestroy, IFieldProtectionRuleContainerComponent
{
	@Input() appId: number;
	@Input() projectId: number;

	private _componentRef: ComponentRef<IFieldProtectionRuleContainerComponent>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		const component = await loadModuleAsync('customApp', 'CustomAppFieldProtectionRuleRemoteComponent');
		this._componentRef = this._viewRef.createComponent<IFieldProtectionRuleContainerComponent>(
			component,
			{ injector: this._injector }
		);

		this.setComponentInputs();
		// Subscribe output events
		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('appId', this.appId);
		this._componentRef.setInput('projectId', this.projectId);
	}
}

export const SERVICE_IDENTITIES = [
	'workspace',
	'work',
	'crm',
	'customApp',
	'adminConsole',
	'helpCenter',
	'messenger',
	'analyticsHub',
] as const;

export type ServiceIdentity = (typeof SERVICE_IDENTITIES)[number];

export const CRM_MODULES_IDENTITIES = [
	'CRMRemotePageModule',
	'CrmLeadDetailModule',
	'CrmDealDetailModule',
	'CrmPostDetailModule',
	'CrmDealCreationPopupModule',
	'CrmLeadCreationPopupModule',
	'CrmLeadGridModule',
	'CrmDealGridModule',
	'CrmTopicGridModule',
	'CrmTopicDetailPanelModule',
	'CrmCommunitySettingPopupModule',
	'CrmSpamPageModule',
	'CrmTopicsPageModule',
	'CrmLeadPageModule',
	'CrmDealsPageModule',
	'CrmVisitorGridModule',
	'CrmContactModule',
	'CrmAccountModule',
	'CrmSegmentModule',
	'CrmSettingPopupModule',
	'CrmProductPageModule',
	'CrmProductSettingModule',
] as const;
export const WORK_MODULES_IDENTITIES = [
	'WorkRemotePageModule',
	'WorkRemoteProjectModule',
	'WorkRemoteProjectBoardFilterModule',
	'WorkRemoteProjectViewSettingModule',
	'WorkRemoteProjectDocumentModule',
	'WorkRemoteProjectKanbanViewModule',
	'WorkRemoteProjectCalendarViewModule',
	'WorkRemoteProjectListViewModule',
	'WorkRemoteProjectSettingModule',
	'WorkRemoteProjectActivityModule',
	'WorkRemoteTaskDetailPanelModule',
	'WorkRemoteWorkflowBuilderModule',
	'WorkRemoteProjectSharingSettingModule',
];
export const HOME_MODULES_IDENTITIES = ['HomeRemotePageModule', 'HomeActivityInboxModule'];
export const CUSTOM_APP_MODULES_IDENTITIES = [
	'CustomAppRemotePageModule',
	'CustomAppContainerRemoteModule',
	'CustomAppFormSubmitRemoteModule',
	'CustomAppRecordDetailRemoteModule',
	'RecordDetailLayoutRemoteModule',
	'CustomAppFormBuilderRemoteComponent',
	'CustomAppRecordAppearanceRemoteComponent',
	'CustomAppFieldManagementRemoteComponent',
	'CustomAppDataSourceRemoteComponent',
	'RecordDetailPanelRemoteComponent',
	'CustomAppPDFTemplateRemoteComponent',
	'CustomAppFieldProtectionRuleRemoteComponent'
];
export const ADMIN_CONSOLE_MODULES_IDENTITIES = ['AdminConsoleRemotePageModule','GlobalTrashRemoteComponent'];
export const GUIDANCE_CENTER_MODULES_IDENTITIES = ['GuidanceCenterRemoteModule'];
export const MESSENGER_MODULES_IDENTITIES = ['MessengerRemotePageModule'];
export const ANALYTICS_HUB_MODULES_IDENTITIES = ['AnalyticsHubRemotePageModule'];

export const MODULES_IDENTITIES = [
	...CRM_MODULES_IDENTITIES,
	...WORK_MODULES_IDENTITIES,
	...HOME_MODULES_IDENTITIES,
	...CUSTOM_APP_MODULES_IDENTITIES,
	...ADMIN_CONSOLE_MODULES_IDENTITIES,
	...GUIDANCE_CENTER_MODULES_IDENTITIES,
	...MESSENGER_MODULES_IDENTITIES,
	...ANALYTICS_HUB_MODULES_IDENTITIES,
] as const;

export type ModuleIdentity = (typeof MODULES_IDENTITIES)[number];
